* {
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: inherit;
	}
}

body {
	font-family: 'ubuntu mono';
	font-size: 1rem;
	line-height: 1.2;
	color: #fff;
	background: linear-gradient(to right, #159957, #155799);
}

h1,
h2 {
	font-family: 'Righteous';
	text-align: center;
	margin: 0 0 1rem;
}

#root {
	overflow: hidden;
}

.app {
	max-width: 50rem;
	padding: 1rem;
	margin: auto;
}

form,
.form-group {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	margin: 0 -0.5rem;
}

form {
	padding-bottom: 1rem;

	button {
		font-size: 2rem;
		line-height: 1.2;
		color: #fff;
		position: relative;
		top: 0;
		padding: 0.5rem 2rem;
		border: 0;
		background: #333;
		border-radius: 0.5rem;
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
		cursor: pointer;
		transition: top 0.1s ease-in-out;

		&:hover {
			background-color: #3a3a3a;
		}

		&:active {
			top: 0.125rem;
		}
	}
}

.form-row {
	color: #333;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-between;
	max-width: calc(100% / 3);
	flex: 0 0 calc(100% / 3);
	padding: 0 0.5rem 1rem;

	small {
		color: #fff;
		margin: 0.5rem 0;
	}

	label {
		font-size: 0.875rem;
		line-height: 1.25;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		color: #fff;
		flex: 0 0 100%;
		margin-bottom: 0.5rem;

		small {
			display: block;
			margin: 0 0 0 auto;
		}
	}

	input,
	select {
		font-size: 0.75rem;
		line-height: 2;
		color: inherit;
		height: 2.5rem;
		display: block;
		padding: 0.4375rem 0.5rem;
		border: 1px solid;
		background: #fff;
		border-radius: 0.5rem;
		box-shadow: none;

		&.has--error {
			background-color: rgba(#f00, 0.2);
		}

		&[disabled] {
			background-color: #ccc;
		}

		&:only-of-type {
			width: 100%;
		}

		&:not(:only-of-type) {
			&:first-of-type {
				width: 70%;
			}

			&:last-of-type {
				width: calc(30% - 1rem);
			}
		}

		&[type='file'],
		&[type='color'] {
			padding: 0;
		}

		&[type='color'] {
			border-radius: 0.625rem;
			cursor: pointer;

			&::-webkit-color-swatch {
				border: none;
				border-radius: 0.5rem;
			}

			&::-webkit-color-swatch-wrapper {
				padding: 0;
			}

			&::-moz-color-swatch,
			&::-moz-focus-inner {
				border: none;
				border-radius: 0.5rem;
			}

			&::-moz-focus-inner {
				padding: 0;
			}
		}
	}
}

.form-actions {
	text-align: center;
	flex: 0 0 100%;
}

.form-group {
	.form-row {
		max-width: 50%;
		flex: 0 0 50%;

		&:only-child {
			margin: auto;
		}
	}
}

.preview {
	position: relative;
	left: 50%;
	border: 1px solid;
	margin-bottom: 1rem;
	transform: translateX(-50%);

	* {
		width: 100%;
		flex: 0 0 100%;
	}

	svg {
		width: 100px;
		height: 100px;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		margin: auto;
		stroke: currentColor;
	}

	&:empty {
		background: none !important;
		border: 1px solid;

		&::before {
			content: 'EMPTY';
		}
	}

	&__content {
		text-align: center;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		align-content: center;
		justify-content: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

	&__background {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}
}

social-links {
	display: block;
	padding: 1rem 0;
}

@media (max-width: 768px) {
	.form-row,
	.form-group .form-row {
		max-width: 100%;
		flex: 0 0 100%;
	}
}
